@import "../../theme/variable";
@import "../../theme/rwd";

.exam-container {
  width: 100%;
  max-width: 960px;
  min-height: 100vh;  
  margin: auto;
  margin-top: 10px;

  @include mobile-less() {
    padding-bottom: 120px;
  }

  .header {
    width: 80%;
    margin: auto;
    padding: 10px 20px;
    border-radius: 30px;
    display: flex;
    background-color: #f5f5f5;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
  }

  @mixin background($url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @mixin question-pic {
    height: 250px;
    box-sizing: border-box;
  }

  .question-box {
    flex: 1;
    margin: 8px;
    border: 4px solid #eee;
  }
  .select-answer{
    font-size: 18px;
    padding: 12px;
  }

  .question-description {
    padding: 10px;
  }

  .questionNo {
    background-color: #eee;
    font-size: 18px;
    padding: 8px;
  }

  .question-1-1 {
    @include question-pic();
    @include background("../../assets/images/1_1.png");
  }

  .question-1-2 {
    @include question-pic();
    @include background("../../assets/images/1_2.png");
  }

  .question-1-3 {
    @include question-pic();
    @include background("../../assets/images/1_3.png");
  }

  .question-2-1 {
    @include question-pic();
    @include background("../../assets/images/2_1.png");
  }

  .question-2-2 {
    @include question-pic();
    @include background("../../assets/images/2_2.png");
  }

  .question-2-3 {
    @include question-pic();
    @include background("../../assets/images/2_3.png");
  }

  .question-3-1 {
    @include question-pic();
    @include background("../../assets/images/3_1.png");
  }

  .question-3-2 {
    @include question-pic();
    @include background("../../assets/images/3_2.png");
  }

  .question-3-3 {
    @include question-pic();
    @include background("../../assets/images/3_3.png");
  }

  .question-4-1 {
    @include question-pic();
    @include background("../../assets/images/4_1.png");
  }

  .question-4-2 {
    @include question-pic();
    @include background("../../assets/images/4_2.png");
  }

  .question-4-3 {
    @include question-pic();
    @include background("../../assets/images/4_3.png");
  }

  .question-5-1 {
    @include question-pic();
    @include background("../../assets/images/5_1.png");
  }

  .question-5-2 {
    @include question-pic();
    @include background("../../assets/images/5_2.png");
  }

  .question-5-3 {
    @include question-pic();
    @include background("../../assets/images/5_3.png");
  }

  .question-6-1 {
    @include question-pic();
    @include background("../../assets/images/6_1.png");
  }

  .question-6-2 {
    @include question-pic();
    @include background("../../assets/images/6_2.png");
  }

  .question-6-3 {
    @include question-pic();
    @include background("../../assets/images/6_3.png");
  }

  .question-7-1 {
    @include question-pic();
    @include background("../../assets/images/7_1.png");
  }

  .question-7-2 {
    @include question-pic();
    @include background("../../assets/images/7_2.png");
  }

  .question-7-3 {
    @include question-pic();
    @include background("../../assets/images/7_3.png");
  }

  .question-8-1 {
    @include question-pic();
    @include background("../../assets/images/8_1.png");
  }

  .question-8-2 {
    @include question-pic();
    @include background("../../assets/images/8_2.png");
  }

  .question-8-3 {
    @include question-pic();
    @include background("../../assets/images/8_3.png");
  }

  .question-9-1 {
    @include question-pic();
    @include background("../../assets/images/9_1.png");
  }

  .question-9-2 {
    @include question-pic();
    @include background("../../assets/images/9_2.png");
  }

  .question-9-3 {
    @include question-pic();
    @include background("../../assets/images/9_3.png");
  }

  .question-10-1 {
    @include question-pic();
    @include background("../../assets/images/10_1.png");
  }

  .question-10-2 {
    @include question-pic();
    @include background("../../assets/images/10_2.png");
  }

  .question-10-3 {
    @include question-pic();
    @include background("../../assets/images/10_3.png");
  }

  .answer-row {
    display: flex;

    @include mobile-less() {
      flex-direction: column;
    }
  }

  .fail-image {
    width: 100%;
    height: 25vh;
    @include background("../../assets/images/ERR.png");
  }

  .success-image {
    width: 100%;
    height: 25vh;
    @include background("../../assets/images/celebreate.png");
  }
  
  .answers-button-box {
    @include mobile-less() {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
          position: fixed;
          background-color: #FFF;
          bottom: 0px;
          width: 100%;
          padding: 12px;
          border-top: 1px solid #eee;
    } 
  }

  .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .answer-button {
    min-width: 50px;
    height: 50px;
    padding: 0px 10px;
    border-radius: 10px;
    background-color: $PRIMARY;
    font-size: 28px;
    line-height: 50px;
    margin: 4px;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    box-shadow: 0px 6px 0px #bf5004;
    @include mobile-less() {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    }
  }

  .answer-button:active {
    transform: translateY(6px);
    box-shadow: 0px 0px 0px #bf5004;
  }
}
