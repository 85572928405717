.login-box {
    position: relative;
    background-color: #fff;
    max-width: 400px;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    display: block;
    margin-top: 60px;
    min-height: 800px;
}
.login-logo {
    border-radius: 100%;
    width: 150px;
    margin-bottom: 19px;
}
.login-box h4 {
    border-bottom: 5px solid #a0a0a01a;
    padding-bottom: 10px;
}
.sub-title {
    color: #ff6906;
}
.btn-butterfly {
    background-color: #ff6906;
    color: #FFF;
}